<template>
  <b-card
      class="brandarchive-edit-wrapper"
  >

    <!-- form -->
    <b-form id="brandarchiveForm" class="edit-form mt-2">

      <!-- 基本信息 -->
      <b-card header="基本信息">

        <b-row>
          <!--指令编号-->
          <!--            <b-col md="4">
                        <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="指令编号"
                            label-for="archive_no"
                            label-size="sm"
                            class="mb-1 required"
                            aria-hidden="true"
                        >
                          <b-form-input
                              id="archive_no"
                              size="sm"
                              v-model="brandarchive.archive_no"
                              readonly
                          />
                        </b-form-group>
                      </b-col>-->
          <b-col md="4">
            <modal-select
                label="供应商 *"
                type="input"
                v-on:change="fromChildren($event,['supplier_name','supplier_id'])"
                :params="['supplier_name','supplier_id']"
                modalName="供应商"
                placeholder="点击选择供应商"
                :isReadonly="this.readonlyFlag"
                v-model="brandarchive.supplier_name"
            >
            </modal-select>
          </b-col>
          <b-col md="4">
            <modal-select
                label="品牌 *"
                type="input"
                v-on:change="fromChildren($event,['brand_name','brand_id'])"
                :params="['brand_name','brand_id']"
                modalName="品牌名称"
                placeholder="点击选择品牌"
                :isReadonly="this.readonlyFlag"
                v-model="brandarchive.brand_name"
            >
            </modal-select>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="区域"
                label-for="area_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="area_name"
                  size="sm"
                  v-model="brandarchive.area_name"
                  :readonly="this.readonlyFlag"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="返利说明"
                label-for="return_memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="return_memo"
                  size="sm"
                  v-model="brandarchive.return_memo"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="remark"
                  size="sm"
                  v-model="brandarchive.memo"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件上传"
                label-for="attachments"
                label-size="sm"
                :class="'mb-1'"
            >
              <attachment-upload v-if="brandarchive.loaded" :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="brandarchive.attachments"
                                 :object_type="'brandarchive'"
                                 :object_id="brandarchive.archive_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  业绩信息 -->
      <b-col md="12">
        <div class="inner-card-title">业绩信息</div>
      </b-col>


      <brand-archive-result-list ref="resultList" v-if="!isEmpty(brandarchive.archive_no)"
                                 :archive_no="brandarchive.archive_no" show_completely="1">
      </brand-archive-result-list>


    </b-form>
    <!--/ form -->
    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          variant="primary"
          class="mr-1"
          @click="save"
          v-if="this.inAudit != 1"
      >
        保存
      </b-button>
      <b-button
          variant="outline-secondary"
          @click="cancel"
      >
        取消
      </b-button>
    </b-col>

  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import brandarchiveStore from './brandarchiveStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import XyInputButton from "@/views/components/xy/XyInputButton";
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import BrandArchiveResultList from "@/views/apps/brandarchiveresult/BrandArchiveResultList";


export default {
  components: {
    BrandArchiveResultList,
    XyInputButton,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      brandarchive: {},
      afterAudit: '',
      inAudit:'',
      readonlyFlag: false,
      status:'',
    }
  },

  methods: {},

  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandarchive')) store.registerModule('brandarchive', brandarchiveStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandarchive')) store.unregisterModule('brandarchive')
    })

    const edit = function () {
      store.dispatch('brandarchive/edit', {id: this.id}).then(res => {
        this.brandarchive = res.data.data
      })
    }

    const view = function () {
      store.dispatch('brandarchive/view', {id: this.id}).then(res => {
        this.brandarchive = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if(this.brandarchive.supplier_name == null){
        toast.error('请选择供应商')
        return false
      }
      if(this.brandarchive.brand_name == null){
        toast.error('请选择品牌')
        return false
      }
      if(this.brandarchive.area_name == null) {
        toast.error('请填写区域')
        return false
      }
      if (this.afterAudit === 0) {
        //审核前编辑
        store.dispatch('brandarchive/save', this.brandarchive).then(res => {
          if(res.data.code == 0){
            toast.success('数据已保存!')
            this.$router.push({name: 'apps-brandarchive-list'});
          }else {
            toast.error(res.data.data)
          }
        })
      } else {
        //审核后编辑
        this.brandarchive.afterAudit = this.afterAudit
        this.$swal({
          title: '确定提交吗',
          text: '提交后将重新审核',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
        }).then(result => {
          if (result.value) {
            store.dispatch('brandarchive/save', this.brandarchive).then(res => {
              if(res.data.code == 0){
                toast.success('数据已保存!')
                this.$router.push({name: 'apps-brandarchive-list'});
              }else {
                toast.error(res.data.data)
              }
            })
          }
        })
      }

    }

    const onUploaded = function (id, attachment, result) {
      this.brandarchive[id] = result
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.brandarchive[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      onUploaded,
      isEmpty
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.afterAudit = this.$route.query.afterAudit || 0;  //是否为审核后再修改
    this.inAudit = this.$route.query.inAudit || 0;  //是否为审核中
    this.status = this.$route.query.status || 0;
    //若审核通过或审核中则 供应商、品牌、区域只读
    ((this.afterAudit == 1 && this.status !=4) || this.inAudit == 1) ? this.readonlyFlag = true : this.readonlyFlag = false
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
