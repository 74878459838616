<template>

  <div>

    <!-- Table Container Card -->
    <b-card>

      <div class="m-2" v-if="this.show_completely == 1">

        <b-row>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end" >
              <b-button
                  variant="primary"
                  @click="addArchiveResult()"
              >
                <span class="text-nowrap">添加信息</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div >

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          sticky-header
          noCollapse
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="result_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="dataList"
      >

        <!-- Columns -->
        <template #cell(result_id)="data">
          <b-link>
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(is_tax)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('yesno', data.item.is_tax)}`"
          >
            {{ getCodeLabel('yesno', data.item.is_tax) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="show_completely == 1">
          <b-link @click="delBrandResult(data.item.id)">
            <feather-icon
                icon="XSquareIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2" v-if="listTotals > 0 && this.show_completely == 1">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
          id="archiveResult"
          ok-only
          ok-title="确认"
          @ok="onAddResult"
          centered
          size="lg"
          :title="'请填写年度业绩'"
      >
        <b-col md="12">
          <b-row>
            <b-col md="4">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="年度"
                  label-for="in_year"
                  class="mb-1 required"
              >
                <b-form-input
                    id="in_year"
                    size="sm"
                    type="number"
                    v-model="in_year"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="业绩(万)"
                  label-for="result_value"
                  class="mb-1 required"
              >
                <b-form-input
                    id="result_value"
                    size="sm"
                    type="number"
                    v-model="result_value"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="含税"
                  label-for="is_tax"
                  class="mb-1"
              >
                <b-form-checkbox
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                    id="is_tax"
                    v-model="is_tax"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import brandarchiveresultUseList from './brandarchiveresultUseList'
import brandarchiveresultStore from './brandarchiveresultStore'
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      in_year: '',
      result_value: '',
      is_tax: '',
      dataList: [],
    }
  },
  props: {
    archive_no: {
      type: String,
      default: ""
    },
    show_completely: {
      type: String,
      default: "0"
    }
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('brandarchiveresult')) store.registerModule('brandarchiveresult', brandarchiveresultStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandarchiveresult')) store.unregisterModule('brandarchiveresult')
    })

    const addArchiveResult = function () {
      this.$bvModal.show('archiveResult')
    }

    const onAddResult = function () {
      let in_year = this.in_year
      let result_value = this.result_value
      let is_tax = this.is_tax != null && this.is_tax !== "" ? this.is_tax : 0
      let archive_no = props.archive_no


      if (in_year === "" || in_year === undefined) {
        toast.error("请填写 年份！")
        return false
      }
      if (result_value === "" || result_value === undefined) {
        toast.error("请填写 业绩！")
        return false
      }

      store.dispatch('brandarchiveresult/save', {
        inYear: in_year,
        resultValue: result_value,
        isTax: is_tax,
        archiveNo: archive_no
      }).then(res => {
        if (res.data.code == 0) {
          toast.success("添加成功！")
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const delBrandResult = function (item_id) {
      this.$swal({
        title: '确定删除这条数据吗?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('brandarchiveresult/del', {
            id: item_id
          }).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = brandarchiveresultUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      addArchiveResult,
      onAddResult,
      delBrandResult,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
